.main-footer {

    background: color('footer-bg', '', '', $colors-footer);

    .footer-section {
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: 1px solid #d3d3d3;

        h4 {
            &.block-title {
                font-size: 17px;
                font-weight: 600;
                color: color('footer-section-titles', '', '', $colors-footer);
                text-transform: uppercase;

                @media (max-width: $media_lg) {
                    margin-top: 20px;
                }

                &::after {
                    content: " ";
                    display: block;
                    width: 30px;
                    height: 3px;
                    background: color('footer-title-underline', '', '', $colors-footer);
                    margin-top: 20px;
                    margin-bottom: 20px;

                    @media (max-width: $media_lg) {
                        margin: 20px auto;
                    }
                }
            }
        }

        &.footer-main {
            padding-top: 80px;
            padding-bottom: 80px;

            @media (max-width: $media_lg) {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            .footer-contact {
                @media(max-width: $media_lg) {
                    margin-bottom: 20px;
                }

                li {
                    color: color('footer-text', '', '', $colors-footer);
                    font-family: font(sub);
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 1.4;
                    margin-bottom: 15px;
                    padding-left: 30px;
                    position: relative;
                    @include transition(all .3s linear);

                    @media (max-width: $media_lg) {
                        padding-left: 0;
                    }

                    span {
                        font-weight: 400;

                        &.company-name {
                            font-weight: 700;
                        }
                    }

                    i {
                        display: inline-block;
                        font-size: 16px;
                        width: 15px;
                        margin-right: 10px;
                        position: absolute;
                        left: 0;
                        top: 3px;
                        color: color('footer-contact-icons', '', '', $colors-footer);

                        @media (max-width: $media_lg) {
                            position: relative;
                        }
                    }

                    a {
                        color: color('footer-text', '', '', $colors-footer);
                        @include hover-underline(color('footer-hover-text', '', '', $colors-footer));

                        &.additional-links {
                            display: inline;

                            i {
                                position: relative;
                                width: auto;
                                top: 0;
                                margin-right: 0;
                                margin-left: 5px;
                            }
                        }

                        &:hover {
                            color: color('footer-hover-text', '', '', $colors-footer);
                            text-decoration: none;
                        }
                    }

                }
            }

            .footer-nav {
                &.column-2 {
                    -webkit-columns: 2;
                    -moz-columns: 2;
                    columns: 2;

                    @media (max-width: $media_lg) {
                        -webkit-columns: 1;
                        -moz-columns: 1;
                        columns: 1;
                        margin: 0 auto;
                    }
                }

                ul {
                    font-size: 14px;
                    column-gap: 2em;
                    column-width: auto;

                    @media(max-width: $media_md) {
                        text-align: center;
                    }

                    li {
                        font-family: font(sub);
                        font-weight: 300;
                        font-size: 15px;
                        margin-bottom: 15px;
                        line-height: 1;

                        @media(max-width: $media_lg) {
                            display: block;
                        }

                        a {
                            color: color('footer-text', '', '', $colors-footer);
                            display: inline-block;
                            padding: 1px 0;
                            transition: all 0.3s linear;
                            position: relative;
                            width: -webkit-max-content;
                            width: -moz-max-content;
                            width: max-content;
                            @include transition(all .3s linear);
                            @include hover-underline(color('footer-hover-text', '', '', $colors-footer));

                            &:hover {
                                text-decoration: none;
                                color: color('footer-hover-text', '', '', $colors-footer);
                            }
                        }
                    }
                }
            }

            .footer-follow {
                .social-title {
                    @media(min-width: $media_lg) {
                        padding-left: 30%;
                    }
                }
            }

            .footer-social {
                .social-title {
                    font-family: font(sub);
                    font-weight: 700;
                    font-size: 14px;
                    text-align: right;
                    display: block;

                    @media (max-width: $media_lg) {
                        text-align: center;
                    }
                }

                .social-icons {
                    @media (max-width: $media_lg) {
                        text-align: center;
                    }

                    .social-icon {
                        background: color('footer-social-findus-bg', '', '', $colors-footer);
                        text-align: center;
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        margin: 0 2.5px;
                        border: 1px solid color('footer-social-findus-i', '', '', $colors-footer);
                        @include transition(all .3s linear);

                        &:hover {
                            background: transparent;
                            text-decoration: none;
                            border: 1px solid color('footer-social-hover', '', '', $colors-footer);

                            i {
                                color: color('footer-social-hover', '', '', $colors-footer);
                            }
                        }

                        i {
                            font-size: 16px;
                            line-height: 30px;
                            color: color('footer-social-findus-i', '', '', $colors-footer);
                            transition: all .3s linear;
                        }
                    }
                }

                .social-media {
                    @media(max-width: $media_lg) {
                        text-align: center;
                    }

                    .at-share-btn {
                        background-color: transparent !important;
                        border: 1px solid color('footer-social-i', '', '', $colors-footer);
                        border-radius: 0 !important;
                        @include transition(all .3s linear);

                        .at-icon-wrapper {
                            line-height: 20px !important;
                            width: 20px !important;
                            height: 20px !important;
                            text-align: center;
                        }

                        .at-icon {
                            line-height: 20px !important;
                            width: 20px !important;
                            height: 20px !important;
                            fill: color('footer-social-i', '', '', $colors-footer) !important;
                        }

                        &:hover {
                            color: color('footer-social-hover', '', '', $colors-footer);
                            border-color: color('footer-social-hover', '', '', $colors-footer);
                        }

                        .at-icon:hover {
                            fill: color('footer-social-hover', '', '', $colors-footer) !important;
                        }
                    }
                }
            }
        }

        &.footer-bottom {
            color: color('footer-text', '', '', $colors-footer);
            background: color('footer-bg', '', '', $colors-footer);
            border-top: 1px solid rgba(255, 255, 255, 0.1);

            @media(max-width: $media_lg) {
                .col-12 {
                    padding-bottom: 10px;
                }
            }

            .dimedia-logo {
                max-height: 17px;
                width: auto;
                margin-left: 5px;
            }
        }
    }

    .footer-copyright {
        text-align: center;
        margin-top: 1rem;

        span {
            font-family: font(sub);
            font-size: 13px;
            font-weight: 300;
        }
    }

}

@media (max-width: $media_lg) {
    #main-footer {
        .footer-navigation {
            display: none;
        }
    }
}

// STARTUP CODE

/* FOOTER STYLES */
.accent {
    color: color('footer', 'text', 'accent') !important;
    font-weight: 700;
}

.footer {
    font-size: 1.4rem;
    color: color('footer', 'text', 'main');
    padding: 50px 0;

    i {
        color: color('footer', 'text', 'accent');
        margin-right: 10px;
    }

    address {
        margin-bottom: 0;

        i {
            font-size: 16px;

            &.fa-skype {
                font-size: 15px;
            }

            &.fa-envelope {
                font-size: 14px;
            }

            &.fa-mobile {
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                right: 2px;
            }
        }
    }
}

/* NEWSLETTER */
.dmn-input-group {
    max-height: 42px;

    .dmn-form-control {
        padding: 0.95rem 1.5rem;
        background-color: rgba(255, 255, 255, 0.02);
        border-radius: 0;
        border-color: color('border', 'light');
        color: #d6d6d6;
        font-size: 1.4rem;
        max-width: 220px;
        overflow: hidden;

        &:focus {
            box-shadow: 0 0 0 2px fade(color('footer-focus', '', '', $colors-footer), 20%);
        }
    }

    button {
        width: 55px;
        border: 1px solid color('footer-btn-border', '', '', $colors-footer);
        border-radius: 0;
        font-size: 1.8rem;
        padding: 0.65rem 0.75rem;
        justify-content: center;
        @include transition(background 0.15s ease-in-out);

        i {
            margin: 0;
        }

        &:hover {
            background-color: lighten(color('footer-hover-alt', '', '', $colors-footer), 2%);
        }
    }
}

// /* SOCIAL SHARE POPUP/LINKS */


/* TRANSITION/DELAY LOOP */


/* MEDIA QUERIES */
@media (max-width: $media_xl) {
    .footer-partners {
        p {
            img {
                margin: 10px auto 30px;
            }
        }
    }
}

@media (max-width: $media_lg) {
    .footer-links {
        display: none;
    }

    .footer-partners {
        p {
            img {
                display: block;
            }
        }
    }

    .footer-bottom {
        ul {
            flex-direction: column;
            text-align: center;
        }
    }
}

@media (max-width: $media_md) {
    .dmn-input-group {
        max-height: 36px;

        .dmn-form-control {
            padding: 0.95rem 1rem;
            font-size: 1.3rem;
        }

        button {
            width: 40px;
            padding: 0.35rem 0.75rem;
        }
    }
}

@media (max-width: $media_md) {
    .footer-logos {
        height: auto !important;

        li {
            padding-top: 15px;
        }
    }

    .footer-copyright {
        .row {
            div {
                text-align: center !important;
                padding-top: 10px;
            }
        }
    }
}